* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
