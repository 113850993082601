/* Nav-bar */
.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 1em;
}
.nav-sticky {
  position: sticky;
  top: 0px; /* When the element reaches top: 10px, it becomes fixed. */
  z-index: 100;
}
.nav-v1 {
  background-color: rgba(85, 202, 249, 1);
  transition: all 1s ease;
}
.nav-v1 span {
  font-size: 1em;
}
#logo {
  display: inline-block;
  border: 2px solid white;
  border-radius: 50%;
  padding: 0.3em;
  margin: 0.4em 0 0.4em 0.2em;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  cursor: hand;
  color: white;
  font-size: 1.2em;
}
.nav-v2 {
  background-color: rgba(233, 233, 233, 1);
  /*border-bottom: 1px solid #D2D2D2;*/
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
  transition: all 1s ease;
}
.nav-v2 #logo {
  color: #2fc6ee;
  font-size: 1.3em;
  margin: 0.2em 0;
  transition: all 1s ease;
}
.nav-v2 span {
  color: #2fc6ee;
  font-size: 1em;
  transition: all 1s ease;
}
.nav-v2 .nav-list {
  margin: 0.9em 0 0 0;
  transition: all 1s ease;
}
.nav-list {
  list-style-type: none;
  text-align: right;
  display: inline-block;
  padding: 0;
  margin: 1em 0;
}
.nav-list li {
  display: inline-block;
  padding: 0 0.5em;
  color: white;
}
.nav-list a {
  color: white;
  position: relative;
  /*padding-bottom: 0.4em;*/
  cursor: pointer;
  cursor: hand;
}
/*.nav-list a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  .nav-list a.active:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }*/

@media (min-width: 550px) {
  .nav-v1 span {
    font-size: 1.3em;
  }
  .nav-v2 span {
    font-size: 1.2em;
  }
}

@media (min-width: 550px) {
  #logo {
    font-size: 1.8em;
  }
  .nav-list li {
    padding: 0 1em;
  }
}

@media (min-width: 650px) {
  .nav {
    padding: 0.5em 3.5em;
  }
  .nav-list li {
    padding: 0 1.5em;
  }
}

@media (min-width: 950px) {
  .nav {
    padding: 0.5em 6em;
  }
}
