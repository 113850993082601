.about-section {
  /*background: rgba(250, 228, 229, 1);*/
  background: #e5e3dc;
  padding: 5vh;
  color: #404040;
}
.round-border {
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.profile-pic {
  display: block;
  margin: 0 auto;
}
.about-summary {
  text-align: left;
  line-height: 1.5em;
  max-width: 750px;
  margin: 0 auto;
}
.about-summary a {
  color: #4cb6df;
  font-weight: bold;
}
@media (min-width: 650px) {
  .project-card {
    width: 40%;
  }
}
