.section-header {
  font-family: "Lato", sans-serif;
  font-size: 2em;
  display: inline-block;
  padding: 0.2em;
  border-bottom: 3px solid white;
}

.white {
  color: white;
}

.blue-underline {
  border-bottom: 3px solid #55caf9;
}

.navy-underline {
  border-bottom: 3px solid #405977;
}
