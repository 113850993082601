.project-section {
  background-color: white;
  padding-top: 5vh;
  padding-bottom: 3vh;
  color: #404040;
}
.projects-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1600px;
}
.project-card {
  background-color: white;
  max-width: 85%;
  margin: 2.5%;
  padding-bottom: 1em;
  border: 1px solid #ededed;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  line-height: 1.4em;
}
.project-img-container {
  width: 100%;
  height: 60vw;
  overflow: hidden;
}
.project-img-container > .landscape {
  height: auto;
  width: 100%;
}
.project-img-container > .portrait {
  width: 100%;
  height: auto;
}
.project-card p {
  padding: 0 8%;
}
.link-container {
  display: flex;
  justify-content: space-around;
}
.link-container a {
  position: relative;
  padding-bottom: 0.6em;
}
.link-container a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #2fc6ee;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.link-container a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

@media (min-width: 650px) {
  .project-card {
    width: 40%;
  }
  .project-img-container {
    height: 25vw;
  }
}

@media (min-width: 1200px) {
  .project-card {
    width: 40%;
  }
  .project-img-container {
    height: 20vw;
  }
}
